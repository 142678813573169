
import { api } from "@/api/api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, onMounted, PropType, ref } from "@vue/composition-api";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";

export default defineComponent({
  name: "ModifyClosedCourseModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const courseStatuses = ref<string[] | null>();
    const selectedStatus = ref<string>();

    const getDefaultCourseStatuses = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        courseStatuses.value = (await api.course.getCourseDefaultOptions()).data?.courseStatuses;
      });
    };

    const modifyClosedCourse = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.course.reopenCourseAsync(props.courseId, { status: selectedStatus.value });
        openNotification(store, NotificationItemType.Success, "Kurset er reåpnet");
        emit("close");
      });
    };

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.form)?.validate();
      if (!isValid) {
        openNotification(store, NotificationItemType.Error, "Kursstatus må velges");
        return;
      }
      modifyClosedCourse();
    };

    onMounted(async () => {
      await getDefaultCourseStatuses();
    });

    return {
      courseStatuses,
      selectedStatus,
      validateNotEmpty,
      handleSubmit,
    };
  },
});
