var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.contentReady ? _c('BaseLayout', {
    staticClass: "px-0",
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": "",
            "data-cy": "navigateToPreviousPage"
          },
          on: {
            "click": _vm.navigateToCourseList
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, !_vm.hasMemberOrgAccess ? {
      key: "actions",
      fn: function fn() {
        return [_vm.notificationCount > 0 && !_vm.hasMemberOrgAccess ? _c('v-badge', {
          staticClass: "mr-4",
          attrs: {
            "content": _vm.notificationCount,
            "value": _vm.notificationCount,
            "color": "error",
            "overlap": ""
          }
        }, [_c('v-icon', {
          on: {
            "click": function click($event) {
              _vm.isNotificationOpen = !_vm.isNotificationOpen;
            }
          }
        }, [_vm._v(" mdi-bell ")])], 1) : _vm._e(), _vm.isTeachingGrantCalculated ? _c('v-btn', {
          staticClass: "mr-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              return _vm.calculateTeachingGrant();
            }
          }
        }, [_c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-flash")]), _vm._v(" Beregn Opplæringstilskudd ")], 1)]) : _vm._e(), !_vm.hasMemberOrgAccess ? _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "to": _vm.editCourse()
          }
        }, [!_vm.isCourseDone ? _c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-pencil")]), _vm._v(" Rediger ")], 1) : _c('span', [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-magnify")]), _vm._v(" Vis ")], 1)]) : _vm._e(), _vm.isModifyClosedCourseAvailable ? _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "color": "warning"
          },
          on: {
            "click": function click($event) {
              return _vm.reopenCourse();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-lock-open")]), _vm._v(" Reåpne ")], 1) : _vm._e(), _vm.isEndCourseAvailable ? _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "color": "error",
            "to": _vm.endCourse()
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-close")]), _vm._v(" Avslutt ")], 1) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    attrs: {
      "maxWidth": 500
    }
  }, [_c('ModifyClosedCourseModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "courseId": _vm.course.id
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  })], 1) : _vm._e(), [_c('v-row', {
    staticClass: "ma-1"
  }, [_vm.isNotificationOpen && _vm.workingHoursPendingApproval.length > 0 ? _c('v-col', {
    staticClass: "pb-0 pt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('CourseDashboardBanner', {
    attrs: {
      "bannerButtonText": "Vis arbeidstimer",
      "bannerType": "primary",
      "bannerIcon": "mdi-clock",
      "bannerText": "Det er ".concat(_vm.workingHoursPendingApproval.length, " ").concat(_vm.workingHoursPendingApproval.length === 1 ? 'arbeidstime' : 'arbeidstimer', " til godkjenning"),
      "to": _vm.navigateToWorkingHours()
    }
  })], 1) : _vm._e(), _vm.isNotificationOpen && _vm.courseParticipantExpensesPendingApproval.length > 0 ? _c('v-col', {
    staticClass: "pb-0 pt-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('CourseDashboardBanner', {
    attrs: {
      "bannerButtonText": "Vis reise og stipend",
      "bannerType": "primary",
      "bannerIcon": "mdi-clock",
      "bannerText": "Det er ".concat(_vm.courseParticipantExpensesPendingApproval.length, " utlegg til godkjenning"),
      "to": _vm.navigateToCourseParticipantExpenses()
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('IconCards', {
    attrs: {
      "courseParticipantStudents": _vm.courseParticipantStudents,
      "course": _vm.course,
      "isVocationalSchool": _vm.isVocationalSchool,
      "numberOfNewMessages": _vm.newMessages.length,
      "numberOfOpenCourseOrders": _vm.numberOfOpenCourseOrders,
      "numberOfNotes": _vm.numberOfNotes
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": _vm.course.plan.schedules.length > 0 ? 6 : 12
    }
  }, [_c('CourseCard', {
    staticClass: "mb-4",
    attrs: {
      "course": _vm.course,
      "isCalendarValid": _vm.isCalendarValid,
      "curriculum": _vm.curriculum,
      "isVocationalSchool": _vm.isVocationalSchool,
      "maxHours": _vm.curriculum.hoursMax,
      "validationState": _vm.validationState
    }
  }), _vm.course.plan.schedules.length > 0 && !_vm.hasMemberOrgAccess && !_vm.isCourseDone ? _c('v-btn', {
    staticClass: "mb-4",
    attrs: {
      "disabled": !_vm.isCalendarValid,
      "color": "primary",
      "block": ""
    },
    on: {
      "click": _vm.updateSchedule
    }
  }, [_vm._v("Lagre endringer i timeplan")]) : _vm._e()], 1), _vm.course.plan.schedules.length > 0 ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('CourseCalendar', {
    attrs: {
      "course": _vm.course,
      "hoursMax": _vm.curriculum.hoursMax,
      "isCourseDone": _vm.isCourseDone
    },
    on: {
      "updateCalendar": _vm.updateCalendar
    }
  })], 1) : _vm._e()], 1)]], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }