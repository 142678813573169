var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "ma-0",
    attrs: {
      "text": "",
      "prominent": "",
      "color": _vm.bannerType,
      "icon": _vm.bannerIcon
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "grow title font-weight-regular"
  }, [_vm._v(" " + _vm._s(_vm.bannerText) + " ")]), _vm.bannerButtonText ? _c('v-spacer') : _vm._e(), _c('v-col', {
    staticClass: "shrink"
  }, [_vm.bannerButtonText ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": _vm.bannerType,
      "to": _vm.to
    }
  }, [_vm._v(" " + _vm._s(_vm.bannerButtonText) + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }