var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "Velg kursstatus",
      "items": _vm.courseStatuses,
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.selectedStatus,
      callback: function callback($$v) {
        _vm.selectedStatus = $$v;
      },
      expression: "selectedStatus"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }