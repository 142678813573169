var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-grid"
  }, _vm._l(_vm.iconCardsList, function (iconCardItem, index) {
    return _c('v-card', {
      key: index,
      staticClass: "pa-4 d-flex",
      attrs: {
        "flat": "",
        "color": "grey lighten-4",
        "to": iconCardItem.link
      }
    }, [_c('v-icon', {
      attrs: {
        "large": "",
        "color": "primary"
      }
    }, [_vm._v(" " + _vm._s(iconCardItem.icon) + " ")]), _c('div', {
      staticClass: "ml-4"
    }, [_c('div', {
      staticClass: "text-h6"
    }, [_vm._v(" " + _vm._s(iconCardItem.value) + " ")]), _c('div', {
      staticClass: "text-caption text--secondary"
    }, [_vm._v(" " + _vm._s(iconCardItem.headline) + " ")])])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }