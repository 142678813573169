export enum TravelAndExpenseType {
  // Travel to/from (reise)
  GeneralTravelExpenses = "generelle reiseutgifter",
  GeneralTravelExpensesWithOptionalReceipt = "generelle reiseutgifter med valgfri kvittering",
  TravelWithOwnVehicle = "reise eget kjøretøy",
  TravelWithOwnVehicleRaisedRate = "reise eget kjøretøy med avtale om forhøyet sats",
  PassengerCompensation = "passasjergodtgjørelse",

  // Diet
  DietBetweenSixAndTwelve = "diettsats uten overnatting 6 til 12 timer",
  DietOverTwelveHours = "diettsats uten overnatting over 12 timer",
  DietWithAccomodation = "diettsats overnatting",

  // Stipend
  Stipend = "stipend",
}

export enum TravelAndExpenseStatus {
  Draft = "utkast",
  ToApproval = "til godkjenning",
  Approved = "godkjent",
  Rejected = "avvist",
  OrderCreated = "ordre opprettet",
}
