var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.course.startDate && _vm.course.endDate && _vm.course.hoursWithInstructor ? _c('ScheduleForm', {
    attrs: {
      "minDate": _vm.course.startDate,
      "maxDate": _vm.course.endDate,
      "hoursWithInstructor": _vm.course.hoursWithInstructor,
      "schedule": _vm.course.plan.schedules,
      "selectedSchoolRouteId": _vm.course.plan.schoolRouteId,
      "hoursMax": _vm.hoursMax,
      "isFormDisplayed": false,
      "isCourseDone": _vm.isCourseDone,
      "calendarHeight": "550"
    },
    on: {
      "updatePlan": _vm.handleUpdatePlan
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }