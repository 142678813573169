
import { defineComponent, PropType } from "@vue/composition-api";
import { Route } from "vue-router";

export default defineComponent({
  name: "CourseDashboardBanner",
  props: {
    bannerType: {
      type: String,
      default: "primary",
    },
    bannerIcon: {
      type: String,
      default: null,
    },
    bannerText: {
      type: String,
      required: false,
    },
    bannerButtonText: {
      type: String,
      required: false,
    },
    to: {
      type: Object as PropType<Route | string>,
      required: true,
    },
  },
});
